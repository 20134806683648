<template>
  <div class="w-full min-h-screen bg-gray-100">
    <div class=" border-0 text-left shadow-lg flex justify-between sticky top-0 z-50 w-full bg-gradient-to-r from-green-600 via-green-600 to-green-500">
      <div class="flex items-center px-4 md:px-6 py-3 space-x-2">
        <img class="h-5 cursor-pointer scale-105 transform hover:scale-125"  src="../assets/round-logo.png"/>
        <span class="text-xs font-bold text-white cursor-pointer">EGPAF 360<sup>o</sup></span>
      </div>
      <div class="px-6 py-3 items-center flex space-x-5">
        <img @click="goTo(twitter)" class="h-5 cursor-pointer hover:scale-125 transform" src="../assets/twitter.png"/>
        <img @click="goTo(linkedin)"  class="h-4 cursor-pointer hover:scale-125 transform" src="../assets/linkedin.png"/>
        <img @click="goTo(instagram)"  class="h-4 cursor-pointer hover:scale-125 transform" src="../assets/instagram.png"/>
        <img @click="goTo(facebook)"  class="h-4 cursor-pointer hover:scale-125 transform" src="../assets/facebook.png"/>
      </div>
    </div>
    <div class="grid grid-flow-row sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 grid-rows-2 gap-8 pt-8 px-2 md:px-6">
      <!--the inner grid-->
      <div v-for="item in items" :key="item" @click="goTo(item.url)" class=" grid grid-flow-col shadow grid-cols-2 cursor-pointer grid-rows-1 rounded-lg transform md:hover:shadow-md md:hover:scale-105 ">
        <div class="bg-white py-2">
          <div class=" px-4 text-left">
            <div class="text-left">
              <span class="text-xs font-bold text-gray-500">{{item.name}}</span>
            </div>
            <div class=" text-xs mt-3 ml-2 text-gray-600">
              <div v-for="role in item.roles" :key="role.role" class="">
                <div class="flex mt-4 items-center space-x-1">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-green-600" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M17.707 9.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-7-7A.997.997 0 012 10V5a3 3 0 013-3h5c.256 0 .512.098.707.293l7 7zM5 6a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                  </svg>
                  <span>{{role.role}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class=" bg-gray-50 py-2">
          <img :src="`${item.image}`" class=" w-full md:h-80 sm:72 xs:72"/>
        </div>
      </div>
    </div>
     <div class=" translate-y-full transform w-full  text-left py-10  shadow-lg flex justify-between bg-gradient-to-r from-gray-700 via-gray-800 to-gray-600">
      <div class="flex-md items-center space-x-2 text-center w-full">
        <span class="text-xs text-white font-mono cursor-pointer ">Elizabeth Glaser Pediatric AIDS Foundation @ 2021 - {{year}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    msg: String
  },
  data () {
    return {
      twitter: 'https://mobile.twitter.com/EGPAF',
      linkedin: 'https://www.linkedin.com/company/elizabeth-glaser-pediatric-aids-foundation',
      instagram: 'https://www.instagram.com/explore/locations/263307520/elizabeth-glaser-pediatric-aids-foundation/',
      facebook: 'https://web.facebook.com/EGPAF',
      year: new Date().getFullYear(),
      items: [
        { name: 'Activity System', image: require('../assets/activity-system.svg'), url: 'https://www.ihss.pedaids.org/activity/', roles: [{ role: 'Create Activities.' }, { role: 'Manage Activities.' }, { role: 'View Activity Reports.' }] },
        { name: 'Malawi Analytics Platform', image: require('../assets/hr-application.svg'), url: 'https://analytics.hismalawi.org', roles: [{ role: 'View Power BI dashboards.' }, { role: 'View Leaflet maps.' }, { role: 'View Dash dashboards.' }] },
        { name: 'Travel Authorization', image: require('../assets/travel-authorization.svg'), url: 'https://www.ihss.pedaids.org/ta/', roles: [{ role: 'Create & submit a travel request.' }, { role: 'Track travel requests.' }, { role: 'Approve travel requests.' }] },
        { name: 'Human Resources', image: require('../assets/human-resources.svg'), url: 'https://www.ihss.pedaids.org/hr/', roles: [{ role: 'Analyze employee data.' }, { role: 'Manage employees.' }, { role: 'Manage sections..' }] },
        { name: 'DHIS2 Reminder System', image: require('../assets/reminder-system.svg'), url: '', roles: [{ role: 'Assign Reports.' }, { role: 'Manage Reports.' }, { role: 'Manage Facilities.' }] },
        { name: 'Stores Requisition System', image: require('../assets/stores-system.svg'), url: 'https://ihss.hismalawi.org/stores/', roles: [{ role: 'Submit a Requisition Request.' }, { role: 'Manage Stores Requests.' }, { role: 'Track Stores Requests.' }] },
        { name: 'Fleet management', image: require('../assets/../assets/fleet-management.svg'), url: 'https://www.ihss.pedaids.org/fleet/', roles: [{ role: 'Assign vehicle drivers.' }, { icon: require('../assets/hr-application.svg'), role: 'Fuel monitoring.' }, { icon: require('../assets/hr-application.svg'), role: 'Vehicle maintenance.' }] },
        { name: 'Local DHIS2 Instance', image: require('../assets/local-dhis2.svg'), url: 'http://192.168.100.24:8081/dhis-web-commons/security/login.action', roles: [{ icon: require('../assets/hr-application.svg'), role: 'Data collection.' }, { role: 'Data analysis.' }, { role: 'Extract data.' }] },
        { name: 'Cloud DHIS2 Instance', image: require('../assets/eda-dhis.svg'), url: 'https://malawidhis.pedaids.org/malawidhis/', roles: [{ role: 'Data collection.' }, { role: 'Data analysis.' }, { role: 'Extract data.' }] }
      ]
    }
  },
  methods: {
    // go to a particular system
    goTo (url) {
      window.location.href = url
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
